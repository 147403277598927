<!-- Settings -->
<template>
    <div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                 <el-row>
                                    <el-col :span="24" align="right">
                                      <el-dropdown  @command="handleActions($event)" size="medium" split-button class="action_btn">
                                        Actions
                                        <el-dropdown-menu slot="dropdown" class="action_dropdown">
                                          <el-dropdown-item :command="1" >Print</el-dropdown-item>
                                          <el-dropdown-item :command="2" >Download</el-dropdown-item>                                          
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                    </el-col>
                                  </el-row>
                                  <el-row>
                                        <el-col :span="12" align="left" v-if="return_to">
                                          <span v-loadimage="return_to.website_logo" ></span>                                           
                                        </el-col>
                                        <el-col :span="12" align="right">
                                             <b >
                                        <label class="mt-10">{{return_to.address}}, {{return_to.city_details.city_name}},{{return_to.state_details.state_name}} - {{return_to.post_code}}, {{return_to.country}}.</label><br>                                      
                                        <label>Phone: {{return_to.phone}}</label><br>
                                        <label>Fax: {{return_to.fax}}</label><br>
                                        <label>Email: {{return_to.email_address}}</label>
                                        </b>
                                        <br>
                                        </el-col>
                                  </el-row>
                                  <hr class="invoice-line">
                                  <el-row>                                    
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>RETURN ORDER</b></h2>
                                      </el-col>                                                                            
                                  </el-row>
                                  <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>ORDER : </b></span><span><b> {{view.order_details.order_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>CUSTOMER ID : </b></span><span><b>{{customer.customer_id}}</b></span>
                                       </el-col>
                                       <el-col :span="12" align="left">
                                        <span><b>RETURN DATE : </b></span>
                                        <span><b> {{ view.created_at|formatDate }}</b></span>
                                      </el-col>
                                  </el-row>
                                    <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">RETURN FROM : </div>
                                          <div style="padding:3px" v-if="return_from">{{customer?customer.first_name+' '+( customer.last_name ? customer.last_name : ''):''}}</div>
                                        
                                            <div style="padding: 3px;" v-if="return_from"><span v-if="return_from.address_line_2">{{ return_from.address_line_2 }}</span><span v-if="return_from.address_line_1">{{return_from.address_line_1}}</span></div>
                                            <div style="padding: 3px;" v-if="return_from.city_details">{{return_from.city_details.city_name}}</div>
                                            <div style="padding: 3px;" v-if="return_from.state_details">{{return_from.state_details.state_name+' - '+return_from.post_code}}</div>
                                          
                                      </el-col>
                                      <el-col :span="8" align="left" :offset="8" v-if="return_to">
                                         <div class="billing-adr" style="padding-left:10px;">RETURN TO :</div>
                                         <div style="padding:3px" v-if="return_to">{{return_to?return_to.website_name:''}}</div>
                                          <div style="padding: 3px;" v-if="return_to">{{return_to.address}}</div>
                                          <div style="padding: 3px;" v-if="return_to.city_details">{{return_to.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="return_to.state_details">{{return_to.state_details.state_name+' - '+return_to.post_code}}</div>
                                      </el-col>
                                    </el-row>
                                      <div class="row">
                                      <div class="col-xl-12 ">
                                      
                                        <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover" style="margin-top:20px;">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center" style="background-color:#366092; color: #ffffff">
                                                    <th aria-colindex="1" role="cell"><div >PRODUCT #</div></th>
                                                    <th aria-colindex="2" role="cell"><div >PRODUCT DETAILS</div></th>
                                                    <th aria-colindex="2" role="cell"><div >QUANTITY</div></th>
                                                    <th aria-colindex="2" role="cell"><div >PRICE</div></th>
                                                    <th aria-colindex="2" role="cell"><div >TAX</div></th>
                                                    <th aria-colindex="2" role="cell"><div >DISCOUNT</div></th>                                                           
                                                    <th aria-colindex="2" role="cell"><div >AMOUNT</div></th>
                                                </tr>
                                                <tr class="billing-cl3" v-for="item in products" :key="item.id">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{item.product.product_id}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{item.product.name}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div>{{item.return_quantity}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>${{parseFloat(item.unit_price).toFixed(2)}}</div></td>  
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>${{parseFloat(item.unit_price*item.product.tax_amount/100).toFixed(2) == '0.00'?'':'$'+parseFloat(item.unit_price*item.product.tax_amount/100).toFixed(2)}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>${{parseFloat(item.unit_price*item.extra_discount/100).toFixed(2)}}</div></td>    
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>${{parseFloat(item.ret_total).toFixed(2)}}</div></td>
                                                </tr>
                                               <tr class="billing-cl3">
                                                  <td aria-colindex="2" role="cell" colspan="3" >
                                                      <div class="padding-10"><b><u>Other Comments or Return Reasons</u></b></div>
                                                      <div class="padding-10">{{view.return_reason}}</div>
                                                      <div class="padding-10" style="margin-top:40px;"></div>
                                                      </td>
                                                  <td aria-colindex="2" role="cell"  colspan="3" >
                                                      <div class="padding-10">Sub Total</div>
                                                      <div class="padding-10">HST</div>
                                                      <div class="padding-10" v-if="view.discount">Discount(-)</div>
                                                      <div class="padding-10" v-if="view.refund_shipping">Refund Shipping</div>
                                                      <div class="padding-10" v-if="view.refund_adjustment">Refund Adjustment</div>
                                                      <div class="padding-10" v-if="view.adjustment_fee">Restocking Fee</div>
                                                      <hr class="invoice-line">
                                                      <div class="padding-10">Total</div>
                                                      </td>
                                                  <td aria-colindex="2" role="cell" class="text-center">
                                                      <div class="padding-10">${{parseFloat(view.ret_sub_total).toFixed(2)}}</div>
                                                      <div class="padding-10">
                                                          <span v-if="view.ret_tax_total>0">$</span> 
                                                          <span v-if="view.ret_tax_total>0" >{{parseFloat(view.ret_tax_total).toFixed(2)}}</span>
                                                          <span v-else>--</span>
                                                       </div>
                                                       <div class="padding-10" v-if="view.discount">${{parseFloat(view.discount).toFixed(2)}}</div>
                                                      <div class="padding-10" v-if="view.refund_shipping">${{parseFloat(view.refund_shipping).toFixed(2)}}</div>
                                                      <div class="padding-10" v-if="view.refund_adjustment">${{parseFloat(view.refund_adjustment).toFixed(2)}}</div>
                                                      <div class="padding-10" v-if="view.adjustment_fee">${{parseFloat(view.adjustment_fee).toFixed(2)}}</div>
                                                      <hr class="invoice-line">
                                                      <div class="padding-10">${{view.total}}</div>
                                                      </td>  
                                                </tr>
                                            </tbody>
                                        </table>
                                       </div>
                                      </div> 
                                      <el-row>
                                          <el-col class="mt-5">
                                            <h4><u>Refund History</u></h4>
                                          </el-col>
                                        </el-row>
                                          <el-row class="mb-3 text-right" >
                                          <el-col v-if="view.return_amount_type == 'credit_memo'">                                                                                 
                                             <span class="blc-label" >Return Payment Credit Memo</span> 
                                            <span class="ml-2"> {{view.refund_payment.created_at | formatDate}}</span><span style="margin-left:5px;">Paid : </span><span > <b>$ {{view.refund_payment.amount}}</b></span>
                                          </el-col>
                                           <el-col v-if="view.return_amount_type == 'refund_online'">                                                                                 
                                             <span class="blc-label" >Payment Refund Online</span> 
                                            <span class="ml-2"> {{view.refund_payment.created_at | formatDate}}</span><span style="margin-left:5px;">Paid : </span><span > <b>$ {{view.refund_payment.amount}}</b></span>
                                          </el-col>
                                        </el-row>                                
                                       <el-row>                                      
                                      <el-col :span="24" align="left">
                                      <div style="text-align: center;"><b>If you have any questions about this order, please contact</b></div>
                                      <div style="text-align: center; font-size:15px;"><b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b></div>
                                      </el-col>
                                       </el-row>                                     
                              
                               </div>
                            
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getReturnOrder,downloadPdf} from "@/api/returnOrder";
import SendEmail from '@/components/SendEmail'
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[],
      view_type: 'return_order',
      return_from:[],
      return_to:[],
      customer:[],
      products:[],
     
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Order Management", route: "/order/list" },
      { title: "View Order" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  methods: {
    fetchData() {
      this.loading = true
      getReturnOrder(this.$route.params.return_id).then(response => {
        console.log(response);
        this.view = response.data.data,
        this.customer = response.data.data.customer,
        this.return_from = response.data.data.from_address,
        this.return_to = response.data.data.to_address, 
        this.products = response.data.data.products,          
        this.loading = false
      });
    },
    printWindow: function () {		
	     window.print();
    },
    format_date(value){
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }else if (evt === 2){
        this.loading=true;
        downloadPdf(this.$route.params.return_id, this.view_type).then(response => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Return Order.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
      }else if (evt === 3){
        let customer = this.view.customer;
        let order_id = this.view.id;
        let type = this.view_type;

        if(type == 'return_product'){
          type = "Return Product"
        }

        this.$modal.show(SendEmail, {customer, type, order_id}, 
          { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
      }
    } 
  }
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 125px;margin-top:30px;margin-left: 10px;
    }
    
    @media print {
       
        .print-section, .download-btn, .subheader, .action_btn, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 
    

}
 
</style>